<template>
  <div class="wrapper--whats-offer-you d--grid">
    <div class="b2b--section-offer">
      <svg-icon :name="`approved-partner/b2b-section-1`" class="sprite-icons" />
      <p>{{ $t(`${base}.section_1`) }}</p>
    </div>
    <div class="b2b--section-offer">
      <svg-icon :name="`approved-partner/b2b-section-2`" class="sprite-icons" />
      <p>{{ $t(`${base}.section_2`) }}</p>
    </div>
    <div class="b2b--section-offer">
      <svg-icon :name="`approved-partner/b2b-section-3`" class="sprite-icons" />
      <p>{{ $t(`${base}.section_3`) }}</p>
    </div>
    <div class="b2b--section-offer">
      <svg-icon :name="`local-flex/logo-black`" class="sprite-icons" />
      <p>{{ $t(`${base}.section_4`) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LadWhatsOfferYou',
  data() {
    return {
      base: 'approved_partner.whats_offer_you',
    }
  },
}
</script>
